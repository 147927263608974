<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card title="Presentations">

                    <template slot="header_actions">
                        <v-btn small fab rounded
                               @click="set_presentation_fields('new', {})">
                            <v-icon> mdi-plus</v-icon>
                        </v-btn>
                    </template>

                    <v-data-table hide-default-footer light
                                  :headers="headers"
                                  :items="presentations"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  :single-expand="singleExpand"
                                  :expanded.sync="expanded"
                                  show-expand
                                  item-key="id">
                        <template v-slot:item.actions="{ item }">
                            <v-btn fab rounded text small
                                   :to="'/c-panel/download-presentations/'+item.id">
                                <v-icon>mdi-file-download</v-icon>
                            </v-btn>

                            <v-btn fab rounded text small
                                   @click="set_presentation_fields('update', item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn fab rounded text small
                                   @click="delete_presentation(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:expanded-item="{ headers, item, index }">
                            <td :colspan="headers.length">
                                <v-container>
                                    <v-layout>
                                        <v-flex xs12 md4 lg3>
                                            <v-select
                                                    v-model="search.type"
                                                    :items="search.types"
                                                    :rules="[v => !!v || 'Item is required']"
                                                    :item-value="'id'"
                                                    :item-text="'name'"
                                                    label="Search By"
                                                    required="">
                                            </v-select>
                                        </v-flex>

                                        <v-flex xs12 md4 lg3>
                                            <m_select
                                                    :label="'item_number'"
                                                    v-model="search_products"
                                                    :options="products"
                                                    :multiple="true"
                                                    :searchable="true"
                                                    :placeholder="'Select a product'"
                                                    :loading="isLoading"
                                                    @search-change="asyncFind"
                                                    @remove="remove"
                                                    :taggable="true"
                                                    :close-on-select="true"
                                                    :track-by="'item_number'"
                                                    :name="'product'">
                                            </m_select>
                                        </v-flex>

                                        <v-flex xs12 md4 lg3>
                                            <v-btn color="success" @click="add_all()">
                                                Add All Results
                                            </v-btn>
                                        </v-flex>

                                    </v-layout>

                                    <v-layout row wrap>
                                        <v-flex v-if="!item.products.length" xs12 md12 lg12>
                                            <v-alert :value="true" type="info">
                                                You currently have no products in this presentation please add some
                                                above.
                                            </v-alert>
                                        </v-flex>

                                        <v-flex v-for="product in item.products" xs12 sm4 lg3
                                                class="presentation-product">
                                            <v-card
                                                    class="mx-2"
                                                    :flat="false"
                                            >
                                                <v-img
                                                        v-if="product.album.files"
                                                        class="white--text"
                                                        height="175"
                                                        :src="product.album.files[0].url"
                                                >
                                                    <v-card-title>
                                                        <v-btn outlined color="primary" @click="set_edit_product(product)">
                                                            <v-icon>
                                                                mdi-pencil
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-card-title>
                                                </v-img>
                                                <v-card-title v-else>No Image</v-card-title>

                                                <v-card-text>
                                                    <div class="title">
                                                        {{ product.name }}
                                                    </div>
                                                    <div class="info-list">
                                                        <strong>FOB: </strong> {{ product.fob_price +
                                                        ((product.fob_price / 100) * item.shop_percentage_increase) }}
                                                        <br>

                                                        <strong>Material:</strong> {{ product.material }}
                                                        <br>
                                                        <strong>Item#:</strong> {{ product.item_number }}
                                                        <br>
                                                        <strong>Size:</strong> {{ product.item_size }}
                                                        <br>
                                                        <strong>Packing:</strong> {{ product.packaging }}
                                                        <br>
                                                        <strong>Updated: </strong> {{ product.updated_at | formatDate }}
                                                    </div>
                                                </v-card-text>
                                                <v-card-actions>

                                                </v-card-actions>
                                            </v-card>
                                        </v-flex>

                                        <v-flex xs3 md2, lg2 v-for="(product, index) in search_products"
                                                style="margin-bottom:20px;" :key="index">
                                            <div class="image text-xs-center" style="height:100px; overflow: hidden;">
                                                <img style="height:50px;"
                                                     :src="product.album.files[0].url"
                                                     alt="">
                                            </div>
                                            <div>
                                                <span>{{ product.name }}</span>
                                                <br><br>
                                                <span>FOB:</span>{{ product.fob_price }} <br>
                                                <span>Item No:</span>{{ product.item_number }}
                                            </div>
                                        </v-flex>
                                    </v-layout>

                                </v-container>

                            </td>
                        </template>


                    </v-data-table>
                </material-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="edit_products_dialog"
                  :persistent="true"
                  max-width="500">
            <v-card class="px-4">
                <v-card-title
                        class="headline"
                        primary-title
                >
                    Editing Products
                </v-card-title>
                <v-card-text>
                    <v-alert type="info">
                        Warning! anything you change here will change
                        live products.
                    </v-alert>
                    <v-form
                            ref="form"
                            @submit="save_products()"
                            onSubmit="return false;"
                            lazy-validation="">
                        <div v-for="p in edit_products">
                            <v-flex xs12>
                               <h3>
                                   Editing {{ p.name }} - #{{ p.item_number }}
                               </h3>
                            </v-flex>

                            <v-flex xs12>
                                <v-text-field
                                        v-model="p.name"
                                        label="Name"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                        v-model="p.fob_price"
                                        label="Original FOB"
                                        type="number"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                        v-model="p.material"
                                        label="Material"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                        v-model="p.item_size"
                                        label="Item Size"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                        v-model="p.packaging"
                                        label="Packaging"
                                ></v-text-field>
                            </v-flex>
                        </div>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                            color="primary"
                            text
                            @click="edit_products_dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="save_products"
                    >
                        Save
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <cpanel-edit-data-with-return :title="'Editing Presentation'"
                                              :db_link="db_link"
                                              :type="form_type"
                                              :ed_fields="editing_fields"
                                              :run_function="update_presentations"
                ></cpanel-edit-data-with-return>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
    .presentation-product {
        margin-bottom: 15px;
    }

    .multiselect__tags {
        max-height: 55px;
        overflow: hidden;
    }
</style>
<script>
    import m_select from 'vue-multiselect'

    export default {
        components: {m_select},
        data() {
            return {
                expanded: [],
                singleExpand: false,
                presentations: [],
                headers: [
                    {
                        text: 'id',
                        align: 'center',
                        sortable: false,
                        value: 'id'
                    },
                    {
                        text: 'Name',
                        align: 'left',
                        sortable: false,
                        value: 'name'
                    },
                    {
                        text: 'Actions',
                        align: 'center',
                        sortable: false,
                        value: 'actions'
                    },
                ],
                search_products: [],
                search: {
                    type: 1,
                    types: [
                        {
                            id: 1,
                            name: 'categories'
                        },
                        {
                            id: 2,
                            name: 'Item Number / Tags'
                        }
                    ]
                },
                products: [],
                isLoading: false,
                preview_products: [],
                db_link: '',
                form_type: 'new',
                editing_fields: [],
                dialog: false,
                user: {},
                edit_index: null,
                edit_products: [],
                edit_products_dialog: false,
                loading:true,
            }
        },
        created() {
            this.user = this.$store.getters.user;
            this.loadPresentations()
        },
        methods: {
            loadPresentations() {
                this.$http.get('admins/presentations/')
                    .then((response) => {
                        this.presentations = response.data.results;
                        this.notification = true;
                        this.notification_message = '';
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                        this.loading = false;
                    });
            },
            asyncFind(query) {
                this.isLoading = true;
                console.log("EDITING SEARCH");
                if (!!query) {
                    this.$http.get('admins/search/products/' + this.search.type + '/' + query)
                        .then((response) => {
                            console.log(response);
                            this.products = response.data;
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            console.log("Error");
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            },
            add_all() {
                console.log(this.products);
                this.search_products = this.products;
            },
            remove(option, id) {

            },
            async load_products(props) {
                props.item.products = await this.$http
                    .get('/admins/presentation-products/', {params: {presentation: props.item.id}})
                    .then(response => response.data);

                props.expanded = true;
            },
            delete_presentation(props) {
                if (confirm('Are you sure you want to delete this presentation ?')) {
                    console.log('delete');
                    this.presentations.splice(props.index, 1);
                    console.log(props.index);
                }
            },
            update_presentations(type, response) {

                if (type === 'new') {
                    console.log('new presentation push');
                    this.presentations.push(response.data);
                    this.dialog = false;
                } else {
                    this.loadPresentations();
                    // this.presentations[this.edit_index].name = response.data.name;
                    // console.log('update presentation push');
                    this.dialog = false;
                }
            },
            set_edit_product(product) {
                this.edit_products = [];
                this.edit_products.push(product);
                this.edit_products_dialog = true;

            },
            save_products() {
                this.$http.post(
                    this.$http.defaults.baseURL + '/admins/presentation-product-mass-save',this.edit_products).then((response) => {
                        this.edit_products_dialog = false;
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            set_presentation_fields(type, props) {


                this.form_type = type;
                this.db_link = '/admins/presentations/';
                this.editing_fields = [
                    {
                        label: 'Name',
                        type: 'text',
                        value: '',
                        table: 'name',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'User',
                        type: 'hidden',
                        value: this.user.id,
                        table: 'user',
                        required: true,
                        rules: []
                    },
                ];
                if (type === 'update') {
                    console.log(props);

                    this.db_link = '/admins/presentations/' + props.id + '/';
                    this.editing_fields.forEach(function (value, key) {
                        let search_key = value.table;
                        if (typeof props[search_key] != 'undefined' && value.type !== 'hidden') {
                            value.value = props[search_key];
                        }
                    });

                }
                this.dialog = true;
            },

        }
    }
</script>
